import styled from 'styled-components'
import styles from '../styles/404.module.scss'

export default function Custom404() {
  return (
    <>
      <div className={styles.container}>
        <H1>404 - Page Not Found</H1>
      </div>
    </>
  )
}

const H1 = styled.h1`
  color: #444;
  font-size: 5rem;
  font-family: Arial, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 20px;
  display: block;
  padding-top: 75px;
`
